.contactContainer {
    background-image: url("../../Assets/background/contactUs-bkg.png");
    background-size: cover;
    background-attachment: fixed;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}
.contactH1{
    color: white;
    text-align: center;
    color: white;
    font-size: 4vw;
    font-family: 'Dancing Script SemiBold';
    margin-bottom: 3vh;
    padding-top: 22vh;
    padding-left: 8vw;
    text-align: left;
    overflow: hidden;
}

.contactIcon {
    color: white;
    width: 100%;
    margin-left: 7%;

}

.icon {
    height: auto;
    width: 4vw;
    color: white;
}
.iconCap{
    height: auto;
    width: 3vw;
    color: white;
}
.iconMobile{
    height: auto;
    width: 3vw;
    color: white;
}
.colAddress{
    font-size: 75%;
    padding-left: 5vw;
}
.contactTitle{
    padding-left: 4vh;
    width: 25vw;
}
.contactLandline{
    padding-left: 27%;
}
.contactRow{
    width: 88%;
}
.contactFirstRow{
    padding-top: 1vh;
    width: 24vw;
    height: 16vh;
}
.contactSecondRow{
    padding-top: 1.5vh;
    width: 24vw;
    height: 15.8vh;
}
.linkName{
    text-decoration: none;
    color: white;
}
.linkName:hover{
    color: white;
}
.contactLandline{
    margin-left: 1vw;
}
.landline{
    color: white;
    text-decoration: none;
}
.landline:hover{
    color: white;
}
@media all and (max-width: 300px){
    .contactH1 {
        margin-bottom: 0vh;
        padding-top: 10vh;
        padding-left: 5vw;
        color: white;
        font-size: 4vh;
    }
    
    .icon {
        height: auto;
        width: 9vw;
    }
    .iconCap{
        height: auto;
        width: 7vw;
        color: white;
        padding-top: 1vh;
    }
    .iconMobile{
        height: auto;
        width: 7vw;
        color: white;
    }
    .contactTitle{
        padding-left: 2vw;
        width: 33vw;
        font-size: 50%;
    }
    .contactFirstRow{
        padding-top: 0;
        padding-left: 0vw;
        width: 20vw;
        height: 28%;
    }
    .contactSecondRow{
        padding-top: 0;
        width: 20vw;
        height: 28%;
        padding-left: 1vw;
    }
    .gmap{
        padding-top: 3vh;
        height: 25vh;
        width: 85vw;
    }
}

@media all and (min-width: 301px)and (max-width: 600px){
    .contactH1 {
        margin-bottom: 2vh;
        padding-top: 10vh;
        padding-left: 5vw;
        color: white;
        font-size: 4vh;
    }
    .icon {
        height: auto;
        width: 7vw;
    }
    .iconMobile{
        height: auto;
        width: 6vw;
        color: white;
        padding-left: 12%;
    }
    .contactTitle{
        padding-left: 7vw;
        width: 35vw;
        font-size: 60%;
        margin-bottom: 2vh;
    }
    .contactFirstRow{
        padding-top: 3vh;
        width: 15vw;
        height: 30%;
        margin: 0;
        padding: 0;
    }
    .contactSecondRow{
        padding-top: 3vh;
        width: 15vw;
        height: 30%;
        margin: 0;
        padding: 0;
    }
    
    .gmap{
        padding-top: 5vh;
        height: 35vh;
        width: 80vw;
    }
}
@media all and (max-height: 740px){
    .contactTitle{
        padding-left: 4vw;
       
    }
}

@media all and (min-width: 601px) and (max-width: 1000px){
    .contactH1 {
        margin-bottom: 0vh;
        padding-top: 5vh;
        padding-left: 6vw;
        color: white;
    }
    .icon {
        height: auto;
        width: 6vw;
    }
    .iconMobile{
        height: auto;
        width: 4vw;
        color: white;
    }
    .contactTitle{
        padding-left: 7vw;
        width: 30vw;
        font-size: 100%;
        height: auto;
    }
    .contactFirstRow{
        padding-top: 3vh;
        width: 15vw;
        height: 30%;
        margin: 0;
        padding: 0;
    }
    .contactSecondRow{
        padding-top: 3vh;
        width: 15vw;
        height: 30%;
        margin: 0;
        padding: 0;
    }
    .gmap{
        padding-top: 3vh;
        height: 33vh;
        width: 85vw;
    }
    
    .landline{
        color: white;
        text-decoration: none;
        margin: 0;
        padding: 0;
    }
}
@media all and (min-width: 1001px) and (max-width: 1200px){
    .contactH1 {
        margin-bottom: 0vh;
        padding-top: 22vh;
        padding-left: 6vw;
        color: white;
    }
    .icon {
        height: auto;
        width: 6vw;
    }
    .iconCap{
        height: auto;
        width: 7vw;
        color: white;
        padding-top: 1vh;
    }
    .iconMobile{
        height: auto;
        width: 4vw;
        color: white;
        padding-left: 10%;
    }
    .contactTitle{
        padding-left: 5vw;
        width: 30vw;
        font-size: 90%;
    }
    .contactRow{
        width: 90%;
    }
    .contactFirstRow{
        padding-top: 4vh;
        width: 20vw;
    }
    .contactSecondRow{
        padding-top: 3vh;
        width: 20vw;
        height: 24.5%;
    }
    .gmap{
        padding-top: 3vh;
        height: 65vh;
        width: 30vw;
    }
}
@media all and (min-width: 1201px) and (max-width: 1300px){
    .contactH1 {
        margin-bottom: 0vh;
        padding-top: 12vh;
        padding-left: 6vw;
        color: white;
    }
    .icon {
        height: auto;
        width: 6vw;
    }
    .iconMobile{
        height: auto;
        width: 4vw;
        color: white;
        margin-left: 1.5vw;

    }
    .contactTitle{
        padding-left: 5vw;
        width: 25vw;
        font-size: 100%;
    }
    .contactRow{
        width: 90%;
    }
    .contactFirstRow{
        padding-top: 4vh;
        width: 23vw;
    }
    .contactSecondRow{
        padding-top: 3vh;
        width: 23vw;
        height: 26%;
    }
    .gmap{
        padding-top: 3vh;
        height: 60vh;
        width: 30vw;
    }
}