.sopasContainer {
    background-image: url("../../Assets/background/menuBackground.png");
    background-size: cover;
    height: auto;
    width: auto;
    background-attachment: fixed;
    padding-bottom: 0%;
}
.menuHeader{
    text-align: center;
    color: white;
    font-size: 4vw;
    font-family: 'Dancing Script SemiBold';
    padding-top: 20vh;
}
.menu-option:hover{
    color: orange;
}
.options-wrapper {
    display: flex;
    flex-wrap: nowrap;
    word-wrap: normal;
    justify-content: center;
    align-items: center;
    padding-bottom: 8vh;
}

.menu-option {
    text-align: right;
    font-family: 'Raleway';
    font-size: 1.3vw;
    color: gainsboro;
    text-decoration: none;
    padding-right: 2vw;
}
.menuPicked{
    text-align: right;
    font-family: 'Raleway Bold';
    font-size: 1.3vw;
    color: gainsboro;
    text-decoration: none;
    padding-right: 2vw;
}
.menuPicked:hover{
    color: gainsboro;
}
.cardImage{
    padding: 0 0;
    height: 30%;
    border-radius: 0px;
}
.sopasRow{
    padding-top: 4vh;
    padding-left: 25vw;
    padding-bottom: 4vh;
}
.card:hover{
    transform: scale(1.1);
    transition: transform .2s;
    cursor: pointer;
    z-index: 1;

}
.card{
    border-radius: 0 !important;
}
.menuLastRow{
    margin-bottom: 5vh;
}
.modalImage{
    height: auto;
    width:  auto;
    border-top-left-radius: 2%;
    border-top-right-radius: 2%;
}
.flag{
    height: 4vh;
    width: auto;
}
.paragraph{
    width: fit-content;
    margin: 0%;
}
.translate{
    margin-bottom: 0%;
    padding-right: 100%;
}

.priceName{
    background: orange;
    width: fit-content;
    border-radius: 12%;
    padding-left: 8%;
    padding-right: 8%;
}
.modalFooter{
    font-size: 60%;
}
.modalPhoto{
    height: 10vh;
    width: auto;
    margin-left: 5vw;
}
.flag:hover{
    transform: scale(1.2);
    transition: transform .2s;
    cursor: pointer;
}
.pax{
    height: 4vh;
    width: auto;
}
@media all and (min-width: 801px) and (max-width: 1300px){
    .sopasRow{
        margin: 0;
        width: 90vw;
        margin-left: 17vw;
    }
}
@media all and (max-width: 800px){
  .dropdownNav{
    display: block;
    padding-right: 4vw;
  }
  .navbar{
    display: none !important;
  }
  .btn-group-sm>.btn, .btn-sm {
    --bs-btn-padding-y: 0.25rem;
    --bs-btn-padding-x: 0.5rem;
    --bs-btn-font-size: 0.875rem;
    --bs-btn-border-radius: 0.25rem;
  }
  .dropdown-menu.show.dropdown-menu-transparent{
    --bs-dropdown-bg: rgb(0, 0, 0) !important;
  }
  .btn-dark {
    --bs-btn-color: gainsboro;
    font-family: 'Raleway' !important;
    --bs-btn-bg: #21252900 !important;
    --bs-btn-border-color: #21252900 !important;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #21252900 !important;
    --bs-btn-hover-border-color: #21252900 !important;
    --bs-btn-focus-shadow-rgb: 66,70,73;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #4d5154;
    --bs-btn-active-border-color: #373b3e;
    --bs-btn-active-shadow: inset 0 3px 5pxrgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #212529;
    --bs-btn-disabled-border-color: #212529;
  }
  .menuHeader{
      text-align: center;
      color: white;
      font-size: 4vh;
      font-family: 'Dancing Script SemiBold';
      padding-top: 10vh;
  }
  .menu-option {
      text-align: right;
      font-family: 'Raleway';
      font-size:  2vh;
      color: gainsboro;
      text-decoration: none;

  }
  .menuPicked{
      text-align: right;
      font-family: 'Raleway Bold';
      font-size: 2.5vh;
      color: gainsboro;
      text-decoration: none;
      text-decoration: underline #FFA500;
      text-underline-offset: 5px;
  }
  .sopasCol3{
    margin-left: 18vw;
  }
  .sopasRow{
    margin: 0;
    width: 95vw;
    height: 100%;
    padding-left: 15vw;
    margin-right: 0;
    padding-right: 0;
}
}