
.text-container {
    color: white;
    font-size: 3.7vw;
    margin-right: 10%;
    margin-left: 10%;
    font-family: 'Dancing Script SemiBold';
    position: center center;
    overflow: hidden;
    height: 75vh;
    width: auto;
    overflow: hidden;
}
@media all and (max-width: 300px){
    .text-container{
        color: white;
        font-size: 2vh;
        height: 12vh;
    }
}
@media all and (min-width: 301px) and (max-width: 500px){
    
    .text-container{
        color: white;
        font-size: 2.5vh;
        height: 15vh;
    }
}
@media all and (min-width: 501px) and (max-width: 600px){
    
    .text-container{
        color: white;
        font-size: 3vh;
        height: 20vh;
        
    }
}
@media all and (min-width: 601px) and (max-width: 1000px){
    .text-container{
        color: white;
        font-size: 3vh;
        height: 25vh;
        
    }
}