.menuContainer {
    background-image: url("../../Assets/background/menuBackground.png");
    height: 200%;
    background-attachment: fixed;
    overflow-y: hidden;
    overflow-x: hidden;
}
.menuHeader{
    text-align: center;
    color: white;
    font-size: 4vw;
    font-family: 'Dancing Script SemiBold';
    padding-top: 20vh;
}
.menu-option:hover{
    color: orange;
}
.options-wrapper {
    display: flex;
    flex-wrap: nowrap;
    word-wrap: normal;
    justify-content: center;
    align-items: center;
    padding-bottom: 8vh;
}

.menu-option {
    text-align: right;
    font-family: 'Raleway';
    font-size: 1.3vw;
    color: gainsboro;
    text-decoration: none;
    padding-right: 2vw;
}
.menuPicked{
    text-align: right;
    font-family: 'Raleway Bold';
    font-size: 1.3vw;
    color: gainsboro;
    text-decoration: none;
    padding-right: 2vw;
}
.menuPicked:hover{
    color: gainsboro;
}
.cardImage{
    padding: 0 0;
    height: 30%;
    border-radius: 0px;
}
.postresRow{
    padding-top: 4vh;
    padding-left: 33vw;
    padding-bottom: 4vh;
}
.card:hover{
    transform: scale(1.1);
    transition: transform .2s;
    cursor: pointer;
    z-index: 1; 
    
}
.menuPicked:hover{
    color: gainsboro;
}
.cardImage{
    padding: 0 0;
    height: 30%;
    border-radius: 0px;
}
.card:hover{
    transform: scale(1.1);
    transition: transform .2s;
    cursor: pointer;
    z-index: 1; 
    
}
.card{
    border-radius: 0 !important;
}
.menuLastRow{
    margin-bottom: 5vh;
}
.modalImage{
    height: auto;
    width:  auto;
    border-top-left-radius: 2%;
    border-top-right-radius: 2%; 
}
.flag{
    height: 4vh;
    width: auto;
}
.paragraph{
    width: fit-content;
    margin: 0%;
}
.translate{
    margin-bottom: 0%;
    padding-right: 100%;
}

.priceName{
    background: orange;
    width: fit-content;
    border-radius: 12%;
    padding-left: 8%;
    padding-right: 8%;
}
.modalFooter{
    font-size: 60%;
}
.modalPhoto{
    height: 10vh;
    width: auto;
    margin-left: 5vw;
}
.flag:hover{
    transform: scale(1.2);
    transition: transform .2s;
    cursor: pointer;
}
.pax{
    height: 4vh;
    width: auto;
}
@media all and (max-height: 700px){
    .postresRow{
        padding-bottom: 16vh;
    }
}
@media all and (min-height: 701px) and (max-height: 800px){
    .postresRow{
        padding-bottom: 10vh;
    }
}
@media all and (min-height: 801px) and (max-height: 1000px){
    .postresRow{
        padding-bottom: 2vh;
    }
}
@media all and (max-width: 1000px){
    .postresRow{
        width: 95vw;
        height: 100%;
        margin-left: 15vw;
    }
}
@media all and (min-width: 1001px) and (max-width: 1300px){
    .postresRow{
        width: 95vw;
        height: 100%;
        padding-left: 33vw;
    }
}