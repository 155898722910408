.houseSpecialtyContainer{
    background-image: url("../../Assets/home1.png");
    height: auto;
    background-attachment: fixed;
    overflow: hidden;
    padding-bottom: 10vh;
}
.houseSpecialtyHeader{
    text-align: center;
    color: white;
    padding-top: 15vh;
    font-size: 4vw;
    font-family: 'Dancing Script SemiBold';
    padding-bottom: 10vh;
}
.card{
    width: 15vw;
    border-radius: 0%;
}
.specialRow{
    padding-left: 0vw;
    width: 110vw;
}
.card-img{
    height: 30vh;
    border-radius: 0% !important;
}
.translateSpecialty{
    font-size: 100%;
}
.cardTextSpecialty{
    font-family: 'Raleway Bold';
    font-size: 60%;
    height: 0;
    padding-bottom: 2vh;
}

@media all and (max-width: 600px){
    .houseSpecialtyHeader{
        padding-top: 15vh;
        font-size: 10vw;
        padding-bottom: 2vh;
    }
    .cardSpecialty{
        width: 50vw !important;
        height: auto;
        border-radius: 0%;
        margin-bottom: 8vh;
    }
    .specialRow{
        padding-left: 25vw;
        width: 20vw;
    }
    .cardImageSpecialty{
        height: 25vh;
        border-radius: 0% !important;
    }
    .cardTextSpecialty{
        font-weight: bold;
        height: 2vh;
        font-size: 60%;
    
    }
    .translateSpecialty{
        font-size: 100%;
    }
}

@media all and (min-width: 601px)and (max-width: 1000px){
    .houseSpecialtyHeader{
        padding-top: 10vh;
        font-size: 6vw;
        padding-bottom: 2vh;
    }
    .cardSpecialty{
        width: 40vw !important;
        height: auto;
        border-radius: 0%;
        margin-bottom: 8vh;
    }
    .cardImageSpecialty{
        height: 35vh;
        width: auto;
        border-radius: 0% !important;
    }
    .cardTextSpecialty{
        font-weight: bold;
        height: 6vh;
    
    }
    .specialRow{
        width: 12vw !important ;
        padding-left: 35%;
    }
    .translateSpecialty{
        font-size: 100%;
    }
}
@media all and (min-width: 1001px)and (max-width: 1200px){
    .houseSpecialtyHeader{
       padding-left: 6vw;
    }
    .card{
        width: 17vw;
        border-radius: 0%;
    }
    .specialRow{
        padding-left: 0vw;
        width: 120vw;
    }
    .translateSpecialty{
        font-size: 130%;
    }
}
@media all and (min-width: 1201px)and (max-width: 1300px){
    .houseSpecialtyHeader{
       padding-left: 6vw;
    }
    .card{
        width: auto;
        border-radius: 0%;
    }
    .specialRow{
        padding-left: 0vw;
        width: 140vw;
        padding-right: 30% !important;
    }
    .translateSpecialty{
        font-size: 150%;
    }
}