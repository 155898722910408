.visualPage{
    background-image: url("../../Assets/home1.png");
    height: auto;
    background-attachment: fixed;
    overflow: hidden;
}
.visualVideo{
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}