.menuContainer {
    background-image: url("../../Assets/home1.png");
    height: 20vh;
    background-attachment: fixed;
    overflow-y: hidden;
    padding-bottom: 100px;
}
.menuHeader{
    text-align: center;
    color: white;
    font-size: 4vw;
    font-family: 'Dancing Script SemiBold';
    padding-top: 20vh;
}
.options-wrapper {
    display: flex;
    flex-wrap: nowrap;
    word-wrap: normal;
    justify-content: center;
    align-items: center;
    padding-bottom: 8vh;
}
.menuText{
    padding-left: 5vw;
}
.menuTitle{
    width: 55%;
    color: white;
    font-family: 'Raleway Bold';
    font-size: 100%;
    padding-top: 0%;
    text-align: left;

}
.menuPrice{

    color: white;
    font-family: 'Raleway';
    padding-left: 2vw;
}
.menuDescription{
    color:white;
    font-family: 'Raleway';
}
.menuImage{
    padding-left: 60px;
    padding-right: 60px;
    padding-bottom: 3vh;
    height: 65%;
    width: 110%;
}
.menu-option {
    text-align: right;
    font-family: 'Raleway';
    font-size: 1.3vw;
    color: gainsboro;
    text-decoration: none;
    padding-right: 2vw;
}
.menuPicked{
    text-align: right;
    font-family: 'Raleway Bold';
    font-size: 1.3vw;
    color: gainsboro;
    text-decoration: none;
    padding-right: 2vw;
}
.menuPicked:hover{
    color: gainsboro;
}
.cardImage{
    padding: 0 0;
    height: 30%;
    border-radius: 0px;
}
.orderRow{
    padding-top: 4vh;
    padding-left: 25vw;
    padding-bottom: 4vh;
}
.card:hover{
    transform: scale(1.1);
    transition: transform .2s;
    cursor: pointer;
    z-index: 1;

}
.card{
    border-radius: 0 !important;
}
.menuLastRow{
    margin-bottom: 5vh;
}
.modalImage{
    height: auto;
    width:  auto;
    border-top-left-radius: 2%;
    border-top-right-radius: 2%;
}
.flag{
    height: 4vh;
    width: auto;
}
.paragraph{
    width: fit-content;
    margin: 0%;
}
.translate{
    margin-bottom: 0%;
    padding-right: 100%;
}

.priceName{
    background: orange;
    width: fit-content;
    border-radius: 12%;
    padding-left: 8%;
    padding-right: 8%;
}
.modalFooter{
    font-size: 60%;
}
.modalPhoto{
    height: 10vh;
    width: auto;
    margin-left: 5vw;
}
.flag:hover{
    transform: scale(1.2);
    transition: transform .2s;
    cursor: pointer;
}
.pax{
    height: 4vh;
    width: auto;
}
.sideCol3{
    width: 30vw !important;
}
.sideCol4{
    padding-top: 4vh;
}
@media all and (max-width: 1000px){
    .orderRow{
        margin: 0;
        width: 95vw;
        height: 100%;
        padding-left: 15vw;
        margin-right: 0;
        padding-right: 0;
    }
}
@media all and (min-width: 1001px) and (max-width: 1300px){
    .orderRow{
        margin: 0;
        width: 80vw;
        height: 100%;
        padding-left: 25vw;
        padding-top: 0;
        padding-bottom: 0;
    }
}