 .ourGalleryContainer {
    background-color: black;
    height: 100%;
}


.ourGalleryHeader{
    background-attachment: fixed;
    text-align: center;
    color: white;
    padding-top: 15vh;
    font-size: 4vw;
    font-family: 'Dancing Script SemiBold';
    padding-bottom: 10vh;
}

.gallery{
    height:max-content;
    width: 12vw;
    margin: 8%;
    animation: 10s slide-up;
}
@keyframes slide-up {
    from {
      margin-top: 100%;
      height: 300%;
    }
    to {
      margin-top: 0%;
      height: 100%;
    }
  }
.gallery:hover{
    transform: scale(1.1);
    transition: transform .2s;
    z-index: 1; 
}
.galleryLastCol{
    margin-right: 4vw;
}
@media all and (max-width: 1000px){
    .ourGalleryHeader{
        padding-top: 10vh;
        font-size: 10vw;
        font-family: 'Dancing Script SemiBold';
        padding-bottom: 8vh;
        margin-bottom: 2vh;
    }
    .galleryRow{
        width: 130vw;
        margin-right: 5vw;
        padding-right: 40vw;
        
    }
    /* .galleryFirstCol{
        width: 50vw;
        margin-right: 5vw;
    }
    .gallerySecondCol{
        width: 50vw;
        margin-right: 5vw;
    }
    .galleryThirdCol{
        width: 50vw;
        margin-right: 5vw;
    }
    .galleryLastCol{
        width: 50vw;
        margin-right: 5vw;
    }
    .gallery{
        height: auto;
        width: 100vw;
        margin: 8%;
    } */
}