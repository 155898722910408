.menuContainer {
    background-image: url("../../Assets/home1.png");
    height: 100%;
    background-attachment: fixed;
    padding-bottom: 100px;
    overflow-y: hidden;
}
.menuTitle{
    text-align: center;
    color: white;
    font-size: 4vw;
    font-family: 'Dancing Script SemiBold';
    padding-top: 20vh;
}
.options {
    color: white;
    float: left;
    display: flex;
    list-style-type: none;
}

.options-wrapper {
    display: flex;
    flex-wrap: nowrap;
    word-wrap: normal;
    justify-content: center;
    align-items: center;
    padding-bottom: 8vh;
}


.menu-option {
    text-align: right;
    font-family: 'Raleway Bold';
    font-size: 1.3vw;
    color: gainsboro;
    text-decoration: none;
    padding-right: 2vw;
}

.menu-options:hover{
    color: white;
}
.navbar{
    margin-left: 5vw;
}
.specialRow{
    padding-right: 10vw;
    
}
.specialRowOne{
    padding-top: 25vw;
    padding-left: 13vw;
    padding-right: 10vw;
    
}
.tapasImage{
    height: 35.5vh;
    margin-left: 6.5vw;
    
}
.paellasRow{
    padding-top: 4vh;
    padding-left: 25vw;
    padding-bottom: 4vh;
}
.rowImage{
    width: 20vw;

}
.tapasText{
    padding-left: 6.5vw;
    color: white;
    padding-bottom: 4vh;
}
.card{
    border-radius: 0 !important;
}
.menuLastRow{
    margin-bottom: 5vh;
}
.modalImage{
    height: auto;
    width:  auto;
    border-top-left-radius: 2%;
    border-top-right-radius: 2%; 
}
.flag{
    height: 4vh;
    width: auto;
}
.paragraph{
    width: fit-content;
    margin: 0%;
}
.translate{
    margin-bottom: 0%;
    padding-right: 100%;
}

.priceName{
    background: orange;
    width: fit-content;
    border-radius: 12%;
    padding-left: 8%;
    padding-right: 8%;
}
.modalFooter{
    font-size: 60%;
}
.modalPhoto{
    height: 10vh;
    width: auto;
    margin-left: 5vw;
}
.flag:hover{
    transform: scale(1.2);
    transition: transform .2s;
    cursor: pointer;
}
.pax{
    height: 4vh;
    width: auto;
}
.col4{
    width: 30vw !important;
}
.col5{
    padding-top: 4vh;
    margin-left: 9vw;
}
.col6{
    padding-top: 4vh;
    margin-left: 1vw;
}
@media all and (max-width: 800px){
    .paellasRow{
        margin: 0;
        width: 95vw;
        height: 100%;
        padding-left: 15vw;
        padding-top: 0;
        padding-bottom: 0;
    }
    .col5{
        margin-left: 0;
    }
    .col6{
        margin-left: 15vw;
    }
    .priceName{
        background: orange;
        width: fit-content;
        border-radius: 12%;
        padding-left: 10%;
        padding-right: 8%;
    }
    
}
@media all and (min-width: 801px) and (max-width: 1300px){
    .paellasRow{
        margin: 0;
        width: 100vw;
        height: 100%;
        padding-left: 25vw;
        padding-top: 3vh;
        padding-bottom: 0;
    }
    .col5{
        padding-top: 4vh;
        margin-left: 9vw;
    }
    .col6{
        padding-top: 4vh;
        margin-left: 1vw;
    }
    .priceName{
        background: orange;
        font-size: 80%;
        width: fit-content;
        border-radius: 12%;
        padding-left: 10%;
        padding-right: 8%;
    }
}