.menuContainer {
    background-image: url("../../Assets/background/menuBackground.png");
    background-size: cover;
    background-attachment: fixed;
    padding-bottom: 0%;
    
}
.dropdownNav{
  display: none;
}
.btn-group-sm>.btn, .btn-sm {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-border-radius: 0.25rem;
}
.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #21252900 !important;
  --bs-btn-border-color: #21252900 !important;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #424649;
  --bs-btn-hover-border-color: #373b3e;
  --bs-btn-focus-shadow-rgb: 66,70,73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4d5154;
  --bs-btn-active-border-color: #373b3e;
  --bs-btn-active-shadow: inset 0 3px 5pxrgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529;
}

.menuHeader{
    text-align: center;
    color: white;
    font-size: 4vw;
    font-family: 'Dancing Script SemiBold';
    padding-top: 20vh;
}
.menu-option:hover{
    color: orange;
}
.options-wrapper {
    display: flex;
    flex-wrap: nowrap;
    word-wrap: normal;
    justify-content: center;
    align-items: center;
    padding-bottom: 8vh;
}
.menu-option {
    text-align: right;
    font-family: 'Raleway';
    font-size: 1.3vw;
    color: gainsboro;
    text-decoration: none;
    padding-right: 2vw;
}
.menuPicked{
    text-align: right;
    font-family: 'Raleway Bold';
    font-size: 1.3vw;
    color: gainsboro;
    text-decoration: none;
    padding-right: 2vw;
    text-decoration: underline #FFA500;
    text-underline-offset: 5px;
}
.menuPicked.active,
.menuPicked:hover{
    color: white;
    text-decoration: underline #FFA500;
    text-underline-offset: 5px;
}
.card-body{
    font-size: 3vh;
    margin-bottom: 0vh;
    font-family: 'Raleway Bold';
}
.lastMenuBody{
    font-size: 3vh;
    margin-bottom: 5vh;
    font-family: 'Raleway Bold';
}
.cardImage{
    /* height: 30%; */
    border-radius: 0% !important;
}
.cardTitle{
    color: black;
    font-size: 60% !important;
    font-family: 'Raleway Bold';
    height: 4vh;
    width: 20vw;
}
.menuRow{
    padding-top: 3Svh;
    padding-left: 17vw;
    padding-bottom: 5vh;
}
.card:hover{
    transform: scale(1.1);
    transition: transform .2s;
    cursor: pointer;
    z-index: 1;

}
.card{
    border-radius: 0 !important;
}
.menuLastRow{
    margin-bottom: 5vh;
}
.modalImage{
    height: auto;
    width:  25.9vw;
    border-top-left-radius: 2%;
    border-top-right-radius: 2%;
}
.modal-content{
    height: auto;
    width: 26.1vw !important;
    animation: popout 1s ease;
    -webkit-animation: popout 1s ease;
}
.modalForm{
    padding-left: 16%;
}
.modal-backdrop.show {
    opacity: 0.9 !important;
}
@keyframes popout {
    from{transform:scale(0)}
    80%{transform:scale(1.1)}
    to{transform:scale(1)}
}
@-webkit-keyframes popout {
    from{-webkit-transform:scale(0)}
    80%{-webkit-transform:scale(1.2)}
    to{-webkit-transform:scale(1)}
}
.flag{
    height: 4vh;
    width: auto;
}
.paragraph{
    width: auto;
    margin: 0%;
}
.translate{
    font-size: 100% !important;
    margin-bottom: 0%;
    padding-right: 100%;
}
.menuDescription{
    color: black;
}
.priceName{
    background: orange;
    width: fit-content;
    border-radius: 12%;
    padding-left: 8%;
    padding-right: 8%;
}
.modalFooter{
    font-size: 60%;
}
.modalPhoto{
    height: 10vh;
    width: auto;
    margin-left: 5vw;
}
.flag:hover{
    transform: scale(1.2);
    transition: transform .2s;
    cursor: pointer;
}
.pax{
    height: 4vh;
    width: auto;
}
.menuCloseIcon{
    position: absolute;
    z-index: 99;
    width: auto;
    height: 4vh;
    padding-left: 23vw;
    padding-top: 2vh;
    cursor: pointer;
    color: orange;
}
@media all and (max-width: 300px){
    .menuThree{
        width: 25vw !important;
    }
    .menuTwo{
        width: 20vw !important;
    }
    .card:hover{
        transform: none;
        transition: none;
        cursor: none;
        z-index: 1;
    
    }
    .col-md-2{
        width: 32% !important;
        padding: 0;
        margin: 0;
    }
}
@media all and (min-width: 301px) and (max-width: 600px){
    .menuThree{
        width: 25vw !important;
    }
    .menuTwo{
        width: 17vw !important;
    }
    .col-md-2{
        width: 32% !important;
        padding: 0;
        margin: 0;
    }
    
}
@media all and (min-width: 601px) and (max-width: 800px){
    .menuThree{
        width: 25vw !important;
    }
    .menuTwo{
        width: 17vw !important;
    }
    .col-md-2{
        width: 18% !important;
        padding: 0;
        margin: 0;
    }
    
    
}
@media all and (max-width: 700px){
    .dropdownNav{
      display: block;
      padding-right: 4vw;
    }
    .navbar{
      display: none !important;
    }
    .btn-group-sm>.btn, .btn-sm {
      --bs-btn-padding-y: 0.25rem;
      --bs-btn-padding-x: 0.5rem;
      --bs-btn-font-size: 0.875rem;
      --bs-btn-border-radius: 0.25rem;
    }
    .dropdown-menu.show.dropdown-menu-transparent{
      --bs-dropdown-bg: rgb(0 0 0) !important;
    }
    .btn-dark {
      --bs-btn-color: gainsboro;
      font-family: 'Raleway' !important;
      --bs-btn-bg: #21252900 !important;
      --bs-btn-border-color: #21252900 !important;
      --bs-btn-hover-color: #fff;
      --bs-btn-hover-bg: #21252900 !important;
      --bs-btn-hover-border-color: #21252900 !important;
      --bs-btn-focus-shadow-rgb: 66,70,73;
      --bs-btn-active-color: #fff;
      --bs-btn-active-bg: #4d5154;
      --bs-btn-active-border-color: #373b3e;
      --bs-btn-active-shadow: inset 0 3px 5pxrgba(0, 0, 0, 0.125);
      --bs-btn-disabled-color: #fff;
      --bs-btn-disabled-bg: #212529;
      --bs-btn-disabled-border-color: #212529;
    }
    .card:hover{
        transform: none;
        transition: none;
        cursor: none;
        z-index: 1;
    
    }
    .menuHeader{
        text-align: center;
        color: white;
        font-size: 4vh;
        font-family: 'Dancing Script SemiBold';
        padding-top: 10vh;
    }
    .menu-option {
        text-align: right;
        font-family: 'Raleway';
        font-size:  1.5vh;
        color: gainsboro;
        text-decoration: none;

    }
    .menuPicked{
        text-align: right;
        font-family: 'Raleway Bold';
        font-size: 2vh;
        color: gainsboro;
        text-decoration: none;
        text-decoration: underline #FFA500;
        text-underline-offset: 5px;
    }
    .options-wrapper {
        display: flex;
        flex-wrap: nowrap;
        word-wrap: normal;
        justify-content: center;
        align-items: center;
        padding-bottom: 0vh;
    }
    
    .card{
        height: auto;
        width: 30vw;
        margin-right: 3vw;
        padding-bottom: 1vh;
    }
    .lastMenuBody{
        font-size: 3vh;
        margin-bottom: 2vh;
        font-family: 'Raleway Bold';
    }
    .cardTitle{
        font-size: 38% !important;
        height: 1vh;
        width: 20vw;
        margin: 0;
        padding: 0;
    }
    .menuRow{
        margin: 0;
        width: 100vw;
        padding-bottom: 0;
    }
    .col-sm-auto{
        padding-top: 2vh;
        margin-right: 3vw;
        width: 35vw !important;
    }
    
    .modalImage{
        height: auto;
        width:  69.5vw;
    }
    .modal-content{
        height: auto;
        width: 70vw !important;
    }
    .modalForm{
        padding-left: 13% !important;
    }
    .flag{
        height: 5vh;
        width: auto;
        margin-right: 50%;
        margin-bottom: 50%;
    }
    .menuDescription{
        font-size: 100%;
        color: black;
    }
    .paragraph{
        width: fit-content;
        font-size: 91%;
        margin: 0%;
    }
    .priceName{
        font-size: 100%;
    }
    .translate{
        font-size: 100%;
        width: fit-content;
        padding: 0;
        margin: 0;
    }
    .modalFooter{
        font-size: 70%;
    }
    .modalPhoto{
        height: 10vh;
        width: auto;
        margin-left: 5vw;
    }
    .pax{
        height: 6vh;
        width: auto;
    }
    .menuCloseIcon{
        padding-left: 83%;
        padding-top: 5%;
        height: auto;
        width: 10%;
        
    }
}
@media all and (min-width: 701px) and (max-width: 800px){
    .dropdownNav{
        display: block;
        padding-right: 4vw;
      }
      .navbar{
        display: none !important;
      }
      .btn-group-sm>.btn, .btn-sm {
        --bs-btn-padding-y: 0.25rem;
        --bs-btn-padding-x: 0.5rem;
        --bs-btn-font-size: 0.875rem;
        --bs-btn-border-radius: 0.25rem;
      }
      .dropdown-menu.show.dropdown-menu-transparent{
        --bs-dropdown-bg: rgb(0 0 0) !important;
      }
      .btn-dark {
        --bs-btn-color: gainsboro;
        font-family: 'Raleway' !important;
        --bs-btn-bg: #21252900 !important;
        --bs-btn-border-color: #21252900 !important;
        --bs-btn-hover-color: #fff;
        --bs-btn-hover-bg: #21252900 !important;
        --bs-btn-hover-border-color: #21252900 !important;
        --bs-btn-focus-shadow-rgb: 66,70,73;
        --bs-btn-active-color: #fff;
        --bs-btn-active-bg: #4d5154;
        --bs-btn-active-border-color: #373b3e;
        --bs-btn-active-shadow: inset 0 3px 5pxrgba(0, 0, 0, 0.125);
        --bs-btn-disabled-color: #fff;
        --bs-btn-disabled-bg: #212529;
        --bs-btn-disabled-border-color: #212529;
      }
      .card:hover{
          transform: none;
          transition: none;
          cursor: none;
          z-index: 1;
      
      }
      .menuHeader{
          text-align: center;
          color: white;
          font-size: 4vh;
          font-family: 'Dancing Script SemiBold';
          padding-top: 10vh;
      }
      .menu-option {
          text-align: right;
          font-family: 'Raleway';
          font-size:  1.5vh;
          color: gainsboro;
          text-decoration: none;
  
      }
      .menuPicked{
          text-align: right;
          font-family: 'Raleway Bold';
          font-size: 2vh;
          color: gainsboro;
          text-decoration: none;
          text-decoration: underline #FFA500;
          text-underline-offset: 5px;
      }
      .options-wrapper {
          display: flex;
          flex-wrap: nowrap;
          word-wrap: normal;
          justify-content: center;
          align-items: center;
          padding-bottom: 0vh;
      }
      
      .card{
          height: auto;
          width: 30vw;
          margin-right: 3vw;
          padding-bottom: 1vh;
      }
      .lastMenuBody{
          font-size: 3vh;
          margin-bottom: 2vh;
          font-family: 'Raleway Bold';
      }
      .cardTitle{
          font-size: 38% !important;
          height: 1vh;
          width: 20vw;
          margin: 0;
          padding: 0;
      }
      .menuRow{
          margin: 0;
          width: 100vw;
          padding-bottom: 0;
      }
      .col-sm-auto{
          padding-top: 2vh;
          margin-right: 3vw;
          width: 35vw !important;
      }
      
      .modalImage{
          height: auto;
          width:  69.5vw;
      }
      .modal-content{
          height: auto;
          width: 70vw !important;
      }
      .modalForm{
          padding-left: 0% !important;
      }
      .flag{
          height: 5vh;
          width: auto;
          margin-right: 50%;
          margin-bottom: 50%;
      }
      .menuDescription{
          font-size: 4vw;
          color: black;
      }
      .paragraph{
          width: fit-content;
          font-size: 3.5vw;
          margin: 0%;
      }
      .priceName{
          font-size: 4vw;
      }
      .translate{
          font-size: 3vw !important;
          width: fit-content;
          padding: 0;
          margin: 0;
      }
      .modalFooter{
          font-size: 2vw;
      }
      .modalPhoto{
          height: 10vh;
          width: auto;
          margin-left: 5vw;
      }
      .pax{
          height: 6vh;
          width: auto;
      }
      .menuCloseIcon{
          padding-left: 83%;
          padding-top: 5%;
          height: auto;
          width: 10%;
          
      }
}
@media all and (min-width: 801px) and (max-width: 1300px){
    .menuHeader{
        text-align: center;
        color: white;
        font-size: 5vw;
        font-family: 'Dancing Script SemiBold';
        padding-top: 15vh;
    }
    .menu-option {
        text-align: right;
        font-family: 'Raleway';
        font-size:  75%;
        color: gainsboro;
        text-decoration: none;

    }
    .menuPicked{
        text-align: right;
        font-family: 'Raleway Bold';
        font-size: 75%;
        color: gainsboro;
        text-decoration: none;
        text-decoration: underline #FFA500;
        text-underline-offset: 5px;
    }
    .options-wrapper {
        display: flex;
        flex-wrap: nowrap;
        word-wrap: normal;
        justify-content: center;
        align-items: center;
        padding-bottom: 0vh;
    }

    .card{
        align-items: center;
        margin-left: 0vw;
    }
    .cardTitle{
        font-size: 26% !important;
        height: 1vh;
        width: auto;
        margin: 0;
        padding: 0;
    }
    .menuRow{
        margin: 0;
        width: 100vw;
    }

    .modalImage{
        height: auto;
        width:  49.8vw;
    }
    .modal-content{
        height: auto;
        width: 50vw !important;
    }
    .modalForm{
        padding-left: 15% !important;
    }
    .flag{
        height: 2.5vh;
        width: auto;
    }
    .col-sm-auto{
        padding-top: 2vh;
        margin-right: 3vw;
        width: 15vw !important;
    }
    .col-md-2{
        width: 18% !important;
        padding: 0;
        margin: 0;
    }
    .menuDescription{
        font-size: 100%;
        color: black;
    }
    .paragraph{
        width: fit-content;
        font-size: 100%;
        margin: 0%;
    }
    .priceName{
        font-size: 100%;
    }
    .translate{
        font-size: 120%;
    }
    .modalFooter{
        font-size: 70%;
    }
    .modalPhoto{
        height: 6vh;
        width: auto;
        margin-left: 7vw;
    }
    .menuThree{
        width: 10vw !important;
    }
    .menuTwo{
        width: 8vw !important;
    }
    .pax{
        height: 2vh;
        width: auto;
    }
    .menuCloseIcon{
        padding-left: 86%;
        padding-top: 5%;
        height: auto;
        width: 10%;
    }

}
@media all and (min-width: 1001px) and (max-width: 1300px){
    .cardTitle{
        font-size: 60% !important;
        height: 2vh;
        width: auto;
        margin: 0;
        padding: 0;
    }
    .modalPhoto{
        height: 12vh;
        width: auto;
    }
    .modalImage{
        height: auto;
        width:  39.6vw;
    }
    .modal-content{
        height: auto;
        width: 40vw !important;
    }
    .col-md-2{
        width: 18% !important;
        padding: 0;
        margin: 0;
    }
    .flag{
        height: 6vh;
        width: auto;
        margin-bottom: 100%;
    }
    .paragraph{
        width: fit-content;
        font-size: 150%;
        margin: 0%;
    }
    .menuDescription{
        font-size: 100%;
        color: black;
    }
    .priceName{
        font-size: 150%;
    }
    .modalFooter{
        font-size: 130%;
    }
    .menuThree{
        width: 14vw !important;
    }
    .menuTwo{
        width: 10vw !important;
    }
    .pax{
        height: 6vh;
        width: auto;
    }

}
@media all and (min-width: 1001px) and (max-width: 1200px){
    .translate{
        font-size: 135%;
        padding: 0;
        margin: 0;
    }
}
@media all and (min-width: 1201px) and (max-width: 1400px){
    .translate{
        font-size: 160%;
        padding: 0;
        margin: 0;
    }
}