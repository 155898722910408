.footer{
    background-color: black;
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
}
.footerIcon{
    padding-top: 2vh;
    height: 4vh;
    width: 6vw;
    color: white;
}
.footerIconGrab{
    padding-top: 2vh;
    height: 6vh;
    width: 5vw;
    color: white;
    margin-left: 1vw;
}
.iconRow{
    padding-left: 40vw;
    
}
.footerText{
    color: white;
    font-size: small;
    text-align: center;
}
@media all and (max-width: 1000px){
    .footer{
        height: 8vh;
        margin-top: 5vh;
    }
    .footerIcon{
        padding-top: 2vh;
        height: 2vh;
        width: auto;
        color: white;
    }
    .footerIconGrab{
        padding-top: 2vh;
        height: 4vh;
        width: auto;
        color: white;
        margin-left: 1vw;
        align-items: center;
    }
    .iconRow{
        padding-left: 40vw;
        
    }
    .footerText{
        color: white;
        font-size: small;
        text-align: center;
    }
    .line{
        padding: 0;
        margin: 0;
    }
}
@media all and (min-width: 1001px) and (max-width: 1300px){
    .footer{
        height: 8vh;
        margin-top: 5vh;
    }
    .footerIcon{
        padding-top: 2vh;
        height: 3vh;
        width: auto;
        color: white;
    }
    .footerIconGrab{
        padding-top: 2vh;
        height: 4vh;
        width: auto;
        color: white;
        margin-left: 1vw;
        align-items: center;
    }
    .iconRow{
        padding-left: 42vw;
    }
    .footerText{
        color: white;
        font-size: small;
        text-align: center;
    }
    .line{
        padding: 0;
        margin: 0;
    }
}

