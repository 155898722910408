
img {
  height: auto;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 0;
}

a {
text-decoration: none;
}

.navigator {
  position: absolute;
  top: 10vh;
  right: 5%;
  z-index: 2;
  font-size: 1.25vw;
  font-family: 'Raleway Bold';
}
.navigatorWeb{
  display: block;
}
.navigatorVisible{
  display: none;
}

.navopt {
  text-decoration: none;
  color: white;
  margin-right: 3vw;
}

.navopt.active,
.navopt:hover {
  color: white;
  text-decoration: underline #FFA500;
  text-underline-offset: 5px;
}

.logo {
  height: 18vh;
  width: auto;
  position: absolute;
  top: 4vh;
  left: 5%;
  z-index: 1;
}
.headerNav{
  overflow: hidden;
  padding: 12vh 12vw;
  transition: 0.2s;
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 99;
}
.headerNav.fixed{
  background-color: black;
  transition: .2s ease-in-out;
  position: fixed;
  padding: 5vh 5vw;
}

.headerNav.fixed .logo{
  height: 8vh;
  width: auto;
  top: 1vh;
}
.headerNav.fixed .navigator{
  top: 4vh;
}
@media all and (max-width: 300px){
  .logo {
    height: auto;
    width: 40vw !important;
  }
  .navigator {
    position: absolute;
    top: 8vh;
    right: 5%;
    z-index: 2;
    font-size: 2vw;
    font-family: 'Raleway Bold';
  } 
  .drawerNav{
    width: 300vw;
  }
  .navLogo{
    padding-bottom: 15vh;
    height: 5vh;
  }
  .ant-drawer-wrapper-body{
    background-color: black;
  }
  .ant-btn-primary{
    background: #FFA500 !important;
    border-color: none;
  }
  .navopt{
    margin-bottom: 4vh;
    margin-top: 10vh;
  }
  .ant-drawer-header{
    display: none !important;
  }
  .navigatorWeb{
    display: none;
  }
  .navigatorVisible{
    display: block;
  } 
  .headerNav{
    padding-left: 4vw;
    padding-top: 3vh;
  }
  .headerNav.fixed{
    background-color: black;
    transition: .2s ease-in-out;
    position: fixed;
    padding: 2vh 2vw;
  }
  .iconDrawer{
    color: #FFA500;
  }
  .ant-drawer-content-wrapper{
    width: 18% !important;
  }
}
@media all and (min-width: 301px) and (max-width: 500px){
.logo {
  height: auto;
  width: 30vw !important;
}
.iconDrawer{
  color: #FFA500;
  height: auto;
  width: 6vw;
  padding-left: 3vw;
}
.navigator {
  position: absolute;
  top: 8vh;
  right: 5%;
  z-index: 2;
  font-size: 2vw;
  font-family: 'Raleway Bold';
}
.navLogo{
  padding-bottom: 18vh;
  height: 5vh;
}
.ant-drawer-wrapper-body{
  background-color: black;
}
.ant-btn-primary{
  background: #FFA500 !important;
  border-color: none;
}
.navopt{
  margin-bottom: 4vh;
  margin-top: 10vh;
}
.ant-drawer-header{
  display: none !important;
}
.navigatorWeb{
  display: none;
}
.navigatorVisible{
  display: block;
}
.headerNav{
  padding-left: 4vw;
  padding-top: 3vh;
  padding-bottom: 3vh;
}
.headerNav.fixed{
  background-color: black;
  transition: .2s ease-in-out;
  position: fixed;
  padding: 2vh 2vw;
}
}
@media all and (min-width: 501px) and (max-width: 600px){
  .logo {
    height: auto;
    width: 30vw !important;
  }
  .iconDrawer{
    color: #FFA500;
    height: auto;
    width: 6vw;
    padding-left: 3vw;
  }
  .navigator {
    position: absolute;
    top: 8vh;
    right: 5%;
    z-index: 2;
    font-size: 2vw;
    font-family: 'Raleway Bold';
  }
  .navLogo{
    padding-bottom: 25vh;
    height: 5vh;
  }
  .ant-drawer-wrapper-body{
    background-color: black;
  }
  .ant-btn-primary{
    background: #FFA500 !important;
    border-color: none;
  }
  .navopt{
    font-size: 3.5vh;
    margin-bottom: 4vh;
    margin-top: 10vh;
  }
  .ant-drawer-header{
    display: none !important;
  }
  .navigatorWeb{
    display: none;
  }
  .navigatorVisible{
    display: block;
  } 
  .headerNav{
    padding-left: 4vw;
    padding-top: 3vh;
  }
  .headerNav.fixed{
    background-color: black;
    transition: .2s ease-in-out;
    position: fixed;
    padding: 2vh 2vw;
  }
  }
@media only screen and (min-device-width: 601px) and (max-device-width: 800px){
.logo {
  height: 12vh;
  width: auto !important ;
}
.navigator {
  position: absolute;
  top: 8vh;
  right: 5%;
  z-index: 2;
  font-size: 2vw;
  font-family: 'Raleway Bold';
}
.navLogo{
  padding-bottom: 15vh;
  height: 5vh;
}
.ant-drawer-wrapper-body{
  background-color: black;
}
.ant-btn-primary{
  background: #FFA500 !important;
  border-color: none;
}
.navopt{
  margin-bottom: 4vh;
  margin-top: 10vh;
}
.ant-drawer-header{
  display: none !important;
}
.navigatorWeb{
  display: none;
}
.navigatorVisible{
  display: block;
}
.headerNav{
  padding-left: 4vw;
  padding-top: 3vh;
}
.headerNav.fixed{
  background-color: black;
  transition: .2s ease-in-out;
  position: fixed;
  padding: 2vh 2vw;
}
.iconDrawer{
  color: #FFA500;
  height: auto;
  width: 6vw;
  padding-left: 3vw;
}
}
@media only screen and (min-device-width: 801px) and (max-device-width: 1000px){
  .logo {
    height: auto;
    width: 16vw !important;
  }
  .navigator {
    position: absolute;
    top: 8vh;
    right: 5%;
    z-index: 2;
    font-size: 2vw;
    font-family: 'Raleway Bold';
  } 
  .drawerNav{
    width: 200%;
  }
  .navLogo{
    padding-bottom: 15vh;
    height: 5vh;
  }
  .ant-drawer-wrapper-body{
    background-color: black;
  }
  .ant-btn-primary{
    background: #FFA500 !important;
    border-color: none;
  }
  .navopt{
    margin-bottom: 4vh;
    margin-top: 10vh;
  }
  .ant-drawer-header{
    display: none !important;
  }
  .navigatorWeb{
    display: none;
  }
  .navigatorVisible{
    display: block;
  }
  .headerNav{
    padding-left: 4vw;
    padding-top: 3vh;
  }
  .headerNav.fixed{
    background-color: black;
    transition: .2s ease-in-out;
    position: fixed;
    padding: 2vh 2vw;
  }
  .iconDrawer{
    color: #FFA500;
    height: auto;
    width: 4vw;
    padding-left: 3vw;
  }
  .ant-drawer-content-wrapper{
    width: 18% !important;
  }
  }
  @media only screen and (min-device-width: 1001px) and (max-device-width: 1300px){
    .logo {
      height: auto;
      width: 12vw !important;
      left: 2vw;
    }
    .navigator {
      position: absolute;
      top: 8vh;
      right: 5%;
      z-index: 2;
      font-size: 2vw;
      font-family: 'Raleway Bold';
    } 
    .drawerNav{
      width: 100%;
    }
    .navLogo{
      padding-bottom: 25vh;
      height: 5vh;
    }
    .ant-drawer-wrapper-body{
      background-color: black;
    }
    .ant-btn-primary{
      background: #FFA500 !important;
      border-color: none;
    }
    .navopt{
      margin-bottom: 4vh;
      margin-top: 10vh;
    }
    .ant-drawer-header{
      display: none !important;
    }
    .navigatorWeb{
      display: none;
    }
    .navigatorVisible{
      display: block;
    } 
    .headerNav{
      padding-left: 4vw;
      padding-top: 3vh;
    }
    .headerNav.fixed{
      background-color: black;
      transition: .2s ease-in-out;
      position: fixed;
      padding: 2vh 2vw;
    }
    .iconDrawer{
      color: #FFA500;
      height: auto;
      width: 6vw;
      padding-left: 3vw;
    }
    .ant-drawer-content-wrapper{
      width: 18% !important;
    }
    }