.about-us-container {
    background-color: black;
    height: 100%;
    background-attachment: fixed;

}
.aboutUsHeader{
    text-align: center;
    color: white;
    padding-top: 5vh;
    font-size: 4vw;
    font-family: 'Dancing Script SemiBold';
    margin-bottom: 3vh;
}
.aboutRow{
    width: 100vw;
}
.aboutDescription{
    color: white;
    text-align: justify;
    margin-right: 5vw;
    
}
.aboutImageFirst{
    height: auto;
    width: 25vw;
    margin-left: 5vw;
}
.aboutImageSecond{
    height: auto;
    width: 15.4vw;
    
}
.aboutImageThird{
    padding-top: 10%;
    height: auto;
    width: 15.4vw;

}
.aboutUsFooter{
    color: white;
    padding-left: 6vw;
    padding-right: 4vw;
    padding-bottom: 12vh;
}
.aboutUsFirstFooter{
    font-family: 'Dancing Script SemiBold';
    font-size: 7vh;
    text-align: right;
}
.aboutUsSecondFooter{
    text-align: right;
}
.colFour{
    width: 32vw !important; 
}
.colTwo{
    width: 18vw !important;
}
.colSix{
    width: 50vw !important;
}

@media all and (max-width: 800px){
    .aboutUsHeader{
        font-size: 10vw;
    }
    .aboutDescription{
        margin-left: 8vw; 
        margin-right: 1vw;
    }
    .aboutImageFirst{
        height: auto;
        width: 51vw;
        margin-left: 8vw;
    }
    .aboutImageSecond{
        height: auto;
        width: 30vw;
        margin-left: 2vw;
    }
    .aboutImageThird{
        padding-top: 3vh;
        height: auto;
        width: 30vw;
        margin-left: 2vw;
    }
    .aboutUsFirstFooter{
        font-family: 'Dancing Script SemiBold';
        font-size: 4vh;
        text-align: right;
    }
    .aboutUsSecondFooter{
        text-align: right;
        font-size: 50%;
    }
    .aboutImageCol2{
        width: 10vw;
    }
    .colFour{
        width: 60% !important; 
    }
    .colTwo{
        width: 40% !important;
    }
    .colSix{
        width: 100% !important;
    }
}
@media all and (min-width: 801px) and (max-width: 1000px){
    .aboutDescription{
        margin-left: 8vw; 
        margin-right: 1vw;
    }
    .aboutImageFirst{
        height: auto;
        width: 50vw;
        margin-left: 8vw;
    }
    .aboutImageSecond{
        height: auto;
        width: 30vw;
        
    }
    .aboutImageThird{
        padding-top: 3vh;
        height: auto;
        width: 30vw;
    }
    .aboutUsFirstFooter{
        font-family: 'Dancing Script SemiBold';
        font-size: 4vh;
        text-align: right;
    }
    .aboutUsSecondFooter{
        text-align: right;
        font-size: 50%;
    }
    .aboutImageCol2{
        width: 10vw;
    }
    .colFour{
        width: 62% !important; 
    }
    .colTwo{
        width: 38% !important;
    }
    .colSix{
        width: 96% !important;
    }
}
@media all and (min-width: 1001px) and (max-width: 1300px){
    
    .aboutDescription{
        color: white;
        text-align: justify;
        margin-left: 7vw;
        
    }
    .aboutImageFirst{
        height: auto;
        width: 30vw;
        margin-left: 24vw;
    }
    .aboutImageSecond{
        height: auto;
        width: 18.5vw;
        margin-left: 16vw;
        
    }
    .aboutImageThird{
        padding-top: 3vh;
        height: auto;
        width: 18.5vw;
        margin-left: 16vw;
    }
    
    .colFour{
        width: 40% !important; 
    }
    .colTwo{
        width: 20% !important;
    }
    .colSix{
        width: 100% !important;
    }
}
